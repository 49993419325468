import { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import FilterInput from './FilterInput'
import Graph from './Graph'
import Sidebar from './Sidebar'
import { debounce } from './utils'
import styles from './App.module.css'
import { SetFilterContext, SetInputContext } from './contexts'
import './global.css'
import trueanonLogo from './assets/trueanon_logo2.png'

const url = 'https://trueanon-vis-cb21724787f7.herokuapp.com/posts'
//const url = 'http://localhost:3000/posts'

const TAGS_BLACKLIST = ['guest', 'ben howard', 'noah kulwin', 'aaron good']

function App() {
    const [data, setData] = useState(null)
    const [filterString, setFilterString] = useState('')
    const [inputValue, setInputValue] = useState('')
    const [selectedEpisode, setSelectedEpisode] = useState(null)
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const [isImageLoaded, setIsImageLoaded] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const sidebarContentRef = useRef()

    const updateURL = useCallback(
        (newQueryParams, newHash) => {
            const queryParams = new URLSearchParams(location.search)

            // Update query string parameters
            Object.entries(newQueryParams).forEach(([key, value]) => {
                if (value === null) {
                    queryParams.delete(key) // Remove the parameter if value is null
                } else {
                    queryParams.set(key, value) // Update or add the parameter
                }
            })

            // Construct the new URL
            const updatedHash = newHash ? `#${newHash}` : ''
            navigate(
                `${location.pathname}?${queryParams.toString()}${updatedHash}`,
                {
                    replace: true,
                }
            )
        },
        [location, navigate]
    )

    const handleToggleSidebar = () => {
        if (isSidebarOpen) {
            setIsSidebarOpen(false)
            updateURL({}, '')
        } else {
            setIsSidebarOpen(true)
        }
        sidebarContentRef.current?.scrollTo(0, 0)
    }
    const handleSelectEpisode = useCallback((episode) => {
        setIsSidebarOpen(true)
        setSelectedEpisode(episode)
        setIsImageLoaded(false)
    }, [])

    const setSearch = useMemo(() => {
        return debounce((val) => setFilterString(val), 300)
    }, [])

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
        const sanitizedString = e.target.value.replace(/["]/g, '')
        setSearch(sanitizedString.toLowerCase())
    }

    const handleClearFilter = () => {
        setFilterString('')
        setInputValue('')
    }

    const containerRef = useRef()

    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal

        const fetchData = async (retryCount = 0) => {
            try {
                const response = await global.fetch(url, {
                    method: 'GET',
                    signal,
                })

                if (response.status === 202) {
                    // If the server responds with "Processing", retry after a delay
                    if (retryCount < 5) {
                        // Retry up to 5 times
                        console.log(
                            'Cache is being rebuilt. Retrying in 5 seconds...'
                        )
                        setTimeout(() => fetchData(retryCount + 1), 5000) // Retry after 5 seconds
                    } else {
                        console.error('Cache rebuilding is taking too long.')
                        setData({
                            error: 'Cache is still rebuilding. Please try again later.',
                        })
                    }
                } else if (response.ok) {
                    // If the response is successful, update the data state
                    const json = await response.json()
                    setData(json)
                } else {
                    console.error(
                        `Failed to fetch data: ${response.statusText}`
                    )
                    setData({
                        error: 'Failed to fetch data. Please try again later.',
                    })
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    console.log('Fetch aborted')
                } else {
                    console.error('Error fetching data:', error)
                    setData({
                        error: 'An error occurred. Please try again later.',
                    })
                }
            }
        }

        fetchData()

        return () => {
            controller.abort() // Clean up on component unmount
        }
    }, [])

    const placeholderList = useMemo(() => {
        if (!data) {
            return []
        }
        const result = Object.keys(data.tags)
            .toSorted((a, b) => data.tags[b] - data.tags[a])
            .slice(0, 30)
            .filter((tag) => !TAGS_BLACKLIST.includes(tag))
            .map((tag) => `Search "${tag}"...`)
        return result
    }, [data])

    return (
        <SetFilterContext.Provider value={setFilterString}>
            <SetInputContext.Provider value={setInputValue}>
                <div className={styles.header}>
                    <span>
                        the podcast |{' '}
                        <a href="http://trueanon.com">the store</a>
                    </span>
                </div>
                <div className={styles.container}>
                    <Sidebar
                        isOpen={isSidebarOpen}
                        episode={selectedEpisode}
                        handleToggle={handleToggleSidebar}
                        filterString={filterString}
                        sidebarContentRef={sidebarContentRef}
                        isImageLoaded={isImageLoaded}
                        setIsImageLoaded={setIsImageLoaded}
                        updateURL={updateURL}
                    />

                    {data ? (
                        <div className={styles.controls}>
                            <FilterInput
                                handleChange={handleInputChange}
                                handleClear={handleClearFilter}
                                inputValue={inputValue}
                                placeholderList={placeholderList}
                                updateURL={updateURL}
                            />
                        </div>
                    ) : null}
                    <div className={styles.graph} ref={containerRef}>
                        {data ? (
                            <Graph
                                data={data}
                                containerRef={containerRef}
                                filterString={filterString}
                                handleSelect={handleSelectEpisode}
                                updateURL={updateURL}
                            />
                        ) : (
                            <div className={styles.loading}>
                                <img
                                    src={trueanonLogo}
                                    className={styles.spinner}
                                />
                                Loading...
                            </div>
                        )}
                    </div>
                </div>
            </SetInputContext.Provider>
        </SetFilterContext.Provider>
    )
}

export default App
