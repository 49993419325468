import { useRef, useState, useEffect } from 'react'
import Close from './icons/Close'
import styles from './FilterInput.module.css'
import { useLocation } from 'react-router-dom'

export default function FilterInput({
    handleChange,
    handleClear,
    inputValue,
    placeholderList,
    updateURL,
}) {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [animate, setAnimate] = useState(false)
    const loadedRef = useRef(false)
    const inputRef = useRef()
    const location = useLocation()

    // Clear the input and update the URL to remove the query parameter
    const clearInput = () => {
        inputRef.current.value = ''
        handleClear()
        updateURL({ q: null }, location.hash.replace('#', ''))
    }

    // Animate placeholder text
    useEffect(() => {
        const intervalId = global.setInterval(() => {
            setAnimate(true)
            setTimeout(() => {
                setCurrentIndex((currentIndex + 1) % placeholderList.length)
                setAnimate(false)
            }, 500)
        }, 3000)
        return () => clearInterval(intervalId)
    }, [animate, placeholderList.length, currentIndex])

    // Populate input value from the URL query string on initial load
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const searchQuery = queryParams.get('q')

        if (searchQuery && searchQuery !== inputValue && !loadedRef.current) {
            handleChange({ target: { value: searchQuery } })
            loadedRef.current = true
        }
    }, [location.search, handleChange, inputValue, loadedRef])

    // Update the URL whenever the input value changes
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const currentQuery = queryParams.get('q')

        // Only update the URL if the input value differs from the current query
        if (inputValue && currentQuery !== inputValue) {
            updateURL({ q: inputValue }, location.hash.replace('#', ''))
        } else if (!inputValue && currentQuery) {
            updateURL({ q: null }, location.hash.replace('#', ''))
        }
    }, [inputValue, updateURL, location.search, location.hash])

    return (
        <div className={styles.container}>
            <div className={styles.instruction}>
                Search episode titles, descriptions, or tags
            </div>
            <div className={styles.inputWrapper}>
                <span
                    className={`${styles.placeholder} ${
                        animate ? styles.animate : ''
                    }`}
                >
                    {!inputValue && placeholderList[currentIndex]}
                </span>

                <input
                    className={styles.filter}
                    type="text"
                    onChange={handleChange}
                    ref={inputRef}
                    value={inputValue}
                    placeholder=""
                />

                {inputValue.length > 0 ? (
                    <button className={styles.closeButton} onClick={clearInput}>
                        <Close
                            width="20px"
                            height="20px"
                            className={styles.closeIcon}
                        />
                    </button>
                ) : null}
            </div>
        </div>
    )
}
